<template>
  <Pending v-if="pending"></Pending>
  <PageLayout v-else>
    <template #header>
      <h2 class="display-3 text-center">Liste des inventaires</h2>
      <hr />
      <BaseLegend>
        <p>
          Dans cette section, vous pouvez acceder aux inventaires en attente de
          saisie ainsi qu'afficher les inventaires archivés.
        </p>
      </BaseLegend>
      <hr />
      <div class="p-field p-fluid">
        <label>Afficher :</label>
        <Dropdown
          :options="filterOptions"
          optionLabel="key"
          optionValue="value"
          placeholder="Selectionnez"
          class="mt-1"
          v-model="filterValue"
        ></Dropdown>
      </div>
    </template>
    <template #default>
      <template v-if="filterBoards.length">
        <div class="gap-2 p-3">
          <router-link
            custom
            v-slot="{ navigate }"
            :to="{
              name: 'BoardDetails',
              params: { boardId: board.id },
            }"
            v-for="board in filterBoards"
            :key="board.id"
          >
            <Card @click="navigate">
              <template #subtitle>
                <div
                  class="
                    flex
                    justify-content-between
                    align-items-center
                    text-sm
                  "
                >
                  <span class="font-bold">
                    {{ board.name }}
                  </span>
                  <span
                    class="text-warning flex align-items-center"
                    v-if="board.status === 'validated'"
                    ><i class="pi pi-exclamation-triangle mr-1" />En attente
                    d'envoi</span
                  >
                </div>
              </template>
              <template #content>
                <div class="flex justify-content-between">
                  <span class="text-sm text-muted"
                    >Période du
                    {{ format(parseJSON(board.period), "dd/MM/yyyy") }}</span
                  >
                  <Button
                    label="Voir"
                    class="p-button-sm"
                    :class="
                      board.status === 'sent'
                        ? 'p-button-secondary'
                        : 'p-button-success'
                    "
                    icon="pi pi-eye"
                  ></Button>
                </div>
              </template>
            </Card>
          </router-link>
        </div>
      </template>
      <template v-else>
        <BaseNotFound message="Aucun inventaire" />
      </template>
      <div
        class="flex flex-column justify-content-center align-items-center"
        v-if="!filterBoards.length"
      ></div>
      <div class="flex justify-content-center">
        <router-link
          custom
          v-slot="{ navigate }"
          :to="{ name: 'ZonesList' }"
          v-if="!filterBoards.length && filterValue !== 'sent'"
        >
          <Button
            class="p-button-outlined"
            label="Préparer les zones de stockage"
            @click="navigate"
          ></Button>
        </router-link>
      </div>
    </template>
    <template #footer>
      <ActionsNav>
        <router-link :to="{ name: 'Home' }">
          <BaseButtonSet
            label="Etablissement"
            color="primary"
            icon="fa fa-building"
            rtl
          />
        </router-link>
        <router-link custom v-slot="{ navigate }" :to="{ name: 'ZonesList' }">
          <BaseButtonSet
            color="warning"
            label="Zones de stockages"
            icon="fa fa-boxes"
            @click="navigate"
          />
        </router-link>
      </ActionsNav>
    </template>
  </PageLayout>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import useDatabase from "../../composables/useDatabase";
import { format, parseJSON } from "date-fns";

export default {
  name: "Boards",
  props: ["centerId"],
  setup() {
    const route = useRoute();
    const { centerId } = route.params;

    const { getAll } = useDatabase(`boards`);
    const { docs: boards, error, pending } = getAll();

    const filterValue = ref("active");
    const filterOptions = ref([
      { key: "En cours", value: "active" },
      { key: "Archivés", value: "sent" },
    ]);
    const filterBoards = computed(() => {
      if (filterValue.value === "active") {
        return boards.value.filter(
          (board) => board.center === centerId && board.status !== "sent"
        );
      }
      return boards.value.filter(
        (board) => board.center === centerId && board.status === "sent"
      );
    });

    const items = ref([
      { label: "Ajouter" },
      { label: "Ajouter" },
      { label: "Ajouter" },
    ]);

    return {
      format,
      parseJSON,
      filterBoards,
      error,
      pending,
      filterOptions,
      filterValue,
      items,
    };
  },
};
</script>

<style lang="scss" scoped>
.clip {
  background-color: white;
  &::before {
    content: "";
    background-color: rgba(black, 0.8);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
</style>
